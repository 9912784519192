import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    {/* Start Footer Area */}
    <section className="footer-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <div className="logo mb-3">
                <Link to="/">
                  <h1 style={{ color: "#f04e45" }}>Bawasons</h1>
                </Link>
              </div>
              <p>
                At Bawasons, we are committed to helping individuals and
                businesses achieve their financial goals by offering tailored loan
                solutions.
              </p>
              <ul className="social">
                <li>
                  <b>Follow us:</b>
                </li>
                <li>
                  <Link to="/" target="_blank" rel=" ">
                    <i className="flaticon-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="/" target="_blank" rel=" ">
                    <i className="flaticon-instagram" />
                  </Link>
                </li>
                <li>
                  <Link to="/" target="_blank" rel=" ">
                    <i className="flaticon-facebook" />
                  </Link>
                </li>
                <li>
                  <Link to="/" target="_blank" rel=" ">
                    <i className="flaticon-linkedin" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Quick Links</h3>
              <ul className="quick-links">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/">Our Services</Link>
                </li>
                <li>
                  <Link to="/">Help (FAQ)</Link>
                </li>
                <li>
                  <Link to="/">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Other Resources</h3>
              <ul className="quick-links">
                <li>
                  <Link to="/">Support</Link>
                </li>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/">Apply For Loan</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Contact Us</h3>
              <div className="info-contact">
                <i className="flaticon-pin" />
                <span>A-78 Sector-2 Noida</span>
              </div>
              <div className="info-contact">
                <i className="flaticon-mail" />
              <span>
              <a href="mailto:info@bawasons.com?subject=Subject%20Here&body=Body%20Here">info@bawasons.com</a>
              </span>
              </div>
              <div className="info-contact">
                <i className="flaticon-telephone" />
                <span>
                  <a href="tel:9990799940">999099940</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* End Footer Area */}
    {/* Start Copy Right Area */}
    <div className="copy-right-area">
      <div className="container">
        <div className="copy-right-content">
          <p>
            © 2024 <Link to="/">Bawasons</Link>. All rights reserved.
          </p>
        </div>
      </div>
    </div>
    {/* End Copy Right Area */}
    {/* Start Go Top Area */}
    <div className="go-top">
      <i className="bx bx-chevron-up" />
    </div>
    {/* End Go Top Area */}
  </>
  
  )
}

export default Footer
