import React from 'react'
import { Link } from 'react-router-dom';
// import $ from 'jquery';
// import 'jquery.meanmenu';


const Header = () => {
 
  return (
    <div className="navbar-area">
    <div className="main-responsive-nav">
      <div className="container">
        <div className="main-responsive-menu">
          <div className="logo">
            <Link to="/">
              <h1 style={{ color: "#f04e45" }}>Bawasons</h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="main-navbar">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-md navbar-light">
          <Link className="navbar-brand" to="/">
            <h3 style={{ color: "#f04e45", textTransform: "capitalize" }}>
              Logo
            </h3>
          </Link>
          <div
            className="collapse navbar-collapse mean-menu"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link active"> 
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link"> 
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link"> 
                  Services 
                  <i className="bx bx-chevron-down" />
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/" className="nav-link"> 
                      Home Loan
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link"> 
                      Personal Loan
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link"> 
                      Business Loan
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link"> 
                      Education Loan
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link"> 
                  Funding
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link"> 
                  Loans
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link"> 
                  Contact
                </Link>
              </li>
            </ul>
            <div className="others-options d-flex align-items-center">
              {/* <div class="option-item">
                            <i class="search-btn flaticon-magnifying-glass"></i>
                            <i class="close-btn flaticon-close"></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
  
                                        <button class="search-button" type="submit">
                                                <i class="flaticon-magnifying-glass"></i>
                                            </button>
                                    </form>
                                </div>
                            </div>
                        </div> */}
              <div className="option-item">
                <Link to="/" className="default-btn"> 
                  Apply now
                  <span />
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  
);

  
}

export default Header
